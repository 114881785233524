import {
  SearchResults,
  ProjectSearchResultModel,
  SearchModel,
  Field,
  Filter,
  DateRangeFilter,
  Category,
  CompanySearchResultModel,
  FreeTextSearchType,
} from "@/core/models";
import { HttpService } from "@/core/services";
import { imageOverlay, LatLng } from "leaflet";
import moment from "moment";
import { Route } from "vue-router";
export interface IMaxMinValue {
  min: string;
  max: string;
}

export interface IGeoRange {
  center: number[];
  range: number;
}

export interface IUICompanyFilters {
  counties: string[];
  roles: string[];
  geoRange: IGeoRange;
  polyline: number[][];
  query: string;
  queryType: number | FreeTextSearchType;
  dateUpdated: DateRangeFilter;
}

export interface IUIFilters {
  trackingFilter: string;
  value: IMaxMinValue;
  roles: string[];
  stage: string[];
  appealStatusList: number[];
  applicationType: string[];
  contractStage: string[];
  dates: DateRangeFilter[];
  dateUpdated: DateRangeFilter;
  dateCreated: DateRangeFilter;
  tenderDeadlineDate: DateRangeFilter;
  finishDate: DateRangeFilter;
  decisionDate: DateRangeFilter;
  projectStartDate: DateRangeFilter;
  applicationDate: DateRangeFilter;
  geoRange: IGeoRange;
  query: string;
  queryType: number | FreeTextSearchType;
  categories: string[];
  projectTypes: string[];
  counties: string[];
  materials: string[];
  isSelfBuild: string;
  fundingType: string[];
  constructionType: string[];
  planningAuthority: string[];
  floorArea: IMaxMinValue;
  numberOfParkingSpaces: IMaxMinValue;
  siteArea: IMaxMinValue;
  numberOfStoreys: IMaxMinValue;
  numberOfUnits: IMaxMinValue;
  polyline: number[][];
  isFramework: string;
}
export const DateFields = {
  DateCreated: "DateCreated",
  DateUpdated: "DateUpdated",
  ProjectStartDate: "ProjectStartDate",
  TenderDeadline: "TenderDeadline",
  FinishDate: "FinishDate",
  ApplicationDate: "ApplicationDate",
  DecisionDate: "DecisionDate",
};
export const FieldLabels: { [id: string]: string; } = {
  DateCreated: "Date Created",
  ProjectStartDate: "Start Date",
  DateUpdated: "Date Updated",
  TenderDeadline: "Tender Deadline",
  FinishDate: "Finish Date",
  ApplicationDate: "Application Date",
  DecisionDate: "Decision Date",
};
export class TurnstoneLookupsModel {
  roleList: any[];
  countyList: any[];
  countryList: any[];
  categoryList: any[];
  planningStageList: any[];
  projectTypeList: any[];
  contractStageList: any[];
  fundingTypeList: any[];
  planningAuthorityList: any[];
  constructionTypeList: any[];
  applicationTypeList: any[];
}
class ProjectSearchCache {
  model: SearchResults<ProjectSearchResultModel>;
  filters: any;
  currentFilter: any;
  builtFilter: SearchModel;
  currentRoute: Route;
}
class CompanySearchCache {
  model: SearchResults<CompanySearchResultModel>;
  filters: any;
  currentFilter: any;
  builtFilter: SearchModel;
  currentRoute: Route;
}

export const SearchService = {
  projectSearchCache: new ProjectSearchCache(),
  companySearchCache: new CompanySearchCache(),
  lookupCache: {} as TurnstoneLookupsModel,
  defaultFilter() {
    return {
      trackingFilter: "All",
      appealStatusList: Array<number>(),
      roles: Array<string>(),
      categories: Array<string>(),
      projectTypes: Array<string>(),
      counties: Array<string>(),
      applicationType: Array<string>(),
      geoRange: { center: [], range: 0 },
      polyline: [],
      value: { max: "", min: "" },
      stage: Array<string>(),
      materials: Array<string>(),
      contractStage: Array<string>(),
      dates: Array<DateRangeFilter>(),
      dateUpdated: new DateRangeFilter(),
      dateCreated: new DateRangeFilter(),
      tenderDeadlineDate: new DateRangeFilter(),
      finishDate: new DateRangeFilter(),
      decisionDate: new DateRangeFilter(),
      projectStartDate: new DateRangeFilter(),
      applicationDate: new DateRangeFilter(),
      query: "",
      queryType: FreeTextSearchType.Any,
      isSelfBuild: "false", // default, turn off the self build search for everyone.
      fundingType: [],
      constructionType: [],
      planningAuthority: [],
      floorArea: { max: "", min: "" },
      numberOfParkingSpaces: { max: "", min: "" },
      siteArea: { max: "", min: "" },
      numberOfStoreys: { max: "", min: "" },
      numberOfUnits: { max: "", min: "" },
      isFramework: "Any",
    };
  },
  clearCache() {
    this.projectSearchCache = new ProjectSearchCache();
    this.companySearchCache = new CompanySearchCache();
  },
  searchCompany(
    query: string,
    maxResults: number,
    skipResults: number,
    filter: SearchModel,
    searchId: string,
  ) {
    return HttpService.postData<SearchResults<CompanySearchResultModel>>(
      `search/company?q=${query}&maxResults=${maxResults}&skipResults=${skipResults}&searchId=${searchId}`,
      filter,
    );
  },
  search(
    query: string,
    maxResults: number,
    skipResults: number,
    filter: SearchModel,
    orderBy: string[],
    searchId: string,
  ) {
    let url = `search/project?q=${query}&maxResults=${maxResults}&skipResults=${skipResults}&searchId=${searchId}`;
    if (!!orderBy) {
      url = `${url}&sortByFields=${orderBy.join(",")}`;
    }
    return HttpService.postData<SearchResults<ProjectSearchResultModel>>(
      url,
      filter,
    );
  },
  async getLookups(): Promise<TurnstoneLookupsModel> {
    if (Object.keys(this.lookupCache).length === 0) {
      this.lookupCache = await HttpService.getData<TurnstoneLookupsModel>(
        `lookup/all`,
      );
    }
    return Promise.resolve(this.lookupCache);
  },
  async getLookupVatCodes(): Promise<any[]> {
    return await HttpService.getData<any[]>(
      `lookup/VatCodes`,
    );
  },
  saveProjectSearch(searchObject: ProjectSearchCache) {
    this.projectSearchCache = searchObject;
  },
  saveCompanySearch(searchObject: CompanySearchCache) {
    this.companySearchCache = searchObject;
  },
  loadProjectSearch() {
    return this.projectSearchCache;
  },
  loadCompanySearch() {
    return this.companySearchCache;
  },
  findInCache(projectId: number) {
    if (this.projectSearchCache && this.projectSearchCache.model && this.projectSearchCache.model.results) {
      const res = this.projectSearchCache.model.results.find(x => Number(x.document.projectId) === Number(projectId));
      if (!!res) { return this.projectSearchCache.model.results.indexOf(res); }
    }
    return -1;
  },
  findInCompanyCache(companyId: number) {
    if (this.companySearchCache) {
      const res = this.companySearchCache.model.results.find(x => Number(x.document.companyId) === Number(companyId));
      if (!!res) { return this.companySearchCache.model.results.indexOf(res); }
    }
    return -1;
  },
  isProjectSearchCached() {
    return !!this.projectSearchCache && !!this.projectSearchCache.model;
  },
  isCompanySearchCached() {
    return !!this.companySearchCache && !!this.companySearchCache.model;
  },
  convertCompanySearchModelToFilters(model: SearchModel): IUICompanyFilters {
    const valueObject: IUICompanyFilters = {
      polyline: [],
      query: model.freeText,
      queryType: model.freeTextType || FreeTextSearchType.Any,
      roles: Array<string>(),
      counties: Array<string>(),
      geoRange: { center: [], range: 0 },
      dateUpdated: new DateRangeFilter(),
    };
    model.fields.forEach((field: Field) => {
      switch (field.fieldName) {
        case "DefaultRole":
          {
            field.filters.forEach(filter => {
              valueObject.roles.push(filter.value);
            });
          }
          break;
        case "Location":
          field.filters.forEach(filter => {
            if (!!filter.points) {
              valueObject.polyline = filter.points;
            } else {
              valueObject.geoRange.center = [filter.lat, filter.lng];
              valueObject.geoRange.range = filter.range;
            }
          });
          break;
        case "County":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.counties.push(filter.value);
            }
          });
          break;
        case DateFields.DateUpdated:
          field.filters.forEach(filter => {
            valueObject.dateUpdated = mapDateFilter(filter, field);
          });
          break;
      }
    });
    return valueObject;
  },
  convertProjectSearchModelToFilters(model: SearchModel): IUIFilters {
    // setup intial empty filter object
    const valueObject: IUIFilters = SearchService.defaultFilter();

    // start populating the object from the search model
    valueObject.query = model.freeText;
    valueObject.queryType = model.freeTextType || FreeTextSearchType.Any;

    model.fields.forEach((field: Field) => {
      switch (field.fieldName) {
        case "Location":
          field.filters.forEach(filter => {
            if (!!filter.points) {
              valueObject.polyline = filter.points;
            } else {
              valueObject.geoRange.center = [filter.lat, filter.lng];
              valueObject.geoRange.range = filter.range;
            }
          });
          break;
        case "Value":
          field.filters.forEach(filter => {
            if (filter.from && filter.to) {
              valueObject.value.min = filter.from;
              valueObject.value.max = filter.to;
            } else if (filter.filterType === "LessThan") {
              valueObject.value.max = filter.value;
            } else if (filter.filterType === "GreaterThan") {
              valueObject.value.min = filter.value;
            }
          });
          break;
        case "Companies.RoleName":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.roles.push(filter.value);
            }
          });
          break;
        case "Stage":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.stage.push(filter.value);
            }
          });
          break;
        case "AppealStatus":
          field.filters.forEach(filter => {
            console.log(filter);
            if (filter.filterType === "Compare") {
              valueObject.appealStatusList.push(Number(filter.idValue));
            }
          });
          break;
        case "County":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.counties.push(filter.value);
            }
          });
          break;
        case "Materials":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.materials.push(filter.value);
            }
          });
          break;
        case "ApplicationType":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.applicationType.push(filter.value);
            }
          });
          break;
        case "Categories":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.categories.push(filter.idValue);
            }
          });
          break;
        case "FundingType":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.fundingType.push(filter.value);
            }
          });
          break;
        case "ConstructionType":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.constructionType.push(filter.value);
            }
          });
          break;
        case "PlanningAuthority":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.planningAuthority.push(filter.value);
            }
          });
          break;
        case DateFields.DateUpdated:
          field.filters.forEach(filter => {
            valueObject.dateUpdated = mapDateFilter(filter, field);
          });
          break;
        case DateFields.DateCreated:
          field.filters.forEach(filter => {
            valueObject.dateCreated = mapDateFilter(filter, field);
          });
          break;
        case DateFields.TenderDeadline:
          field.filters.forEach(filter => {
            valueObject.tenderDeadlineDate = mapDateFilter(filter, field);
          });
          break;
        case DateFields.DecisionDate:
          field.filters.forEach(filter => {
            valueObject.decisionDate = mapDateFilter(filter, field);
          });
          break;
        case DateFields.ApplicationDate:
          field.filters.forEach(filter => {
            valueObject.applicationDate = mapDateFilter(filter, field);
          });
          break;
        case DateFields.FinishDate:
          field.filters.forEach(filter => {
            valueObject.finishDate = mapDateFilter(filter, field);
          });
          break;
        case DateFields.ProjectStartDate:
          field.filters.forEach(filter => {
            valueObject.projectStartDate = mapDateFilter(filter, field);
          });
          break;
        case "IsSelfBuild":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.isSelfBuild =
                filter.value === "true" ? "SelfBuildOnly" : "LargeScaleOnly";
            }
          });
          break;
        case "trackingFilter":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.trackingFilter = filter.value;
            }
          });
          break;
        case "FloorArea":
          field.filters.forEach(filter => {
            switch (filter.filterType) {
              case "GreaterThan":
                valueObject.floorArea.min = filter.value;
                valueObject.floorArea.max = "";
                break;
              case "Between":
                valueObject.floorArea.min = filter.from;
                valueObject.floorArea.max = filter.to;
                break;
              case "LessThan":
                valueObject.floorArea.max = filter.value;
                valueObject.floorArea.min = "";
                break;
            }
          });
          break;
        case "NumberOfParkingSpaces":
          field.filters.forEach(filter => {
            switch (filter.filterType) {
              case "GreaterThan":
                valueObject.numberOfParkingSpaces.min = filter.value;
                valueObject.numberOfParkingSpaces.max = "";
                break;
              case "Between":
                valueObject.numberOfParkingSpaces.min = filter.from;
                valueObject.numberOfParkingSpaces.max = filter.to;
                break;
              case "LessThan":
                valueObject.numberOfParkingSpaces.max = filter.value;
                valueObject.numberOfParkingSpaces.min = "";
                break;
            }
          });
          break;
        case "SiteArea":
          field.filters.forEach(filter => {
            switch (filter.filterType) {
              case "GreaterThan":
                valueObject.siteArea.min = filter.value;
                valueObject.siteArea.max = "";
                break;
              case "Between":
                valueObject.siteArea.min = filter.from;
                valueObject.siteArea.max = filter.to;
                break;
              case "LessThan":
                valueObject.siteArea.max = filter.value;
                valueObject.siteArea.min = "";
                break;
            }

          });
          break;
        case "NumberOfStoreys":
          field.filters.forEach(filter => {
            switch (filter.filterType) {
              case "GreaterThan":
                valueObject.numberOfStoreys.min = filter.value;
                valueObject.numberOfStoreys.max = "";
                break;
              case "Between":
                valueObject.numberOfStoreys.min = filter.from;
                valueObject.numberOfStoreys.max = filter.to;
                break;
              case "LessThan":
                valueObject.numberOfStoreys.max = filter.value;
                valueObject.numberOfStoreys.min = "";
                break;
            }
          });
          break;
        case "NumberOfUnits":
          field.filters.forEach(filter => {
            switch (filter.filterType) {
              case "GreaterThan":
                valueObject.numberOfUnits.min = filter.value;
                valueObject.numberOfUnits.max = "";
                break;
              case "Between":
                valueObject.numberOfUnits.min = filter.from;
                valueObject.numberOfUnits.max = filter.to;
                break;
              case "LessThan":
                valueObject.numberOfUnits.max = filter.value;
                valueObject.numberOfUnits.min = "";
                break;
            }
          });
          break;
        case "IsFramework":
          field.filters.forEach(filter => {
            if (filter.filterType === "Compare") {
              valueObject.isFramework =
                filter.value === "true" ? "Yes" : "No";
            }
          });
          break;
      }
    });
    return valueObject;
  },
  buildCompanyFilter(
    polyline: number[][],
    geoRange: IGeoRange,
    counties: string[],
    roles: string[],
    dates: DateRangeFilter[],
  ) {
    const results: Field[] = [];
    if (geoRange.center && geoRange.range && geoRange.range > 0) {
      results.push({
        fieldName: "Location",
        filters: [geographicalRange(geoRange)],
      });
    }

    if (!!polyline && polyline.length > 0) {
      results.push({
        fieldName: "Location",
        filters: [geographicalArea(polyline)],
      });
    }

    if (roles && roles.length) {
      results.push({
        fieldName: "DefaultRole",
        filters: roles.map(role => compare(role)),
      } as Field);
    }
    if (counties && counties.length) {
      results.push({
        fieldName: "County",
        filters: counties.map(county => compare(county)),
      } as Field);
    }
    if (dates) {
      dates.forEach(x => {
        if (x.startDate && x.endDate) {
          results.push({
            fieldName: x.filterName,
            filters: [between(x.startDate, x.endDate)],
          } as Field);
        } else if (x.startDate) {
          results.push({
            fieldName: x.filterName,
            filters: [greaterThan(x.startDate)],
          });
        } else if (x.endDate) {
          results.push({
            fieldName: x.filterName,
            filters: [lessThan(x.endDate)],
          });
        } else if (x.noOfdays || x.value) {
          results.push({
            fieldName: x.filterName,
            filters: [relativeDate(x.value || "", x.noOfdays || 0)],
          } as Field);
        }
      });
    }
    return results;
  },
  buildProjectFilter(
    trackingFilter: string,
    geoRange: IGeoRange,
    valueMin: string,
    valueMax: string,
    stages: string[],
    categories: string[],
    dates: DateRangeFilter[],
    counties: string[],
    isSelfBuild: string,
    roles: string[],
    polyline: number[][],
    fundingTypes: string[],
    constructionTypes: string[],
    planningAuthorities: string[],
    materials: string[],
    floorArea: IMaxMinValue,
    numberOfParkingSpaces: IMaxMinValue,
    siteArea: IMaxMinValue,
    numberOfStoreys: IMaxMinValue,
    numberOfUnits: IMaxMinValue,
    applicationTypes: string[],
    isFramework: string,
    appealStatusList: number[],
  ) {
    const results: Field[] = [];
    if (appealStatusList && appealStatusList.length) {
      results.push({
        fieldName: "AppealStatus",
        filters: appealStatusList.map(status => compareWithId(status.toString())),
      } as Field);
    }
    if (applicationTypes && applicationTypes.length) {
      results.push({
        fieldName: "ApplicationType",
        filters: applicationTypes.map(applicationType => compare(applicationType)),
      } as Field);
    }
    if (materials && materials.length) {
      results.push({
        fieldName: "Materials",
        filters: materials.map(material => compare(material)),
      } as Field);
    }

    if (geoRange.center && geoRange.range && geoRange.range > 0) {
      results.push({
        fieldName: "Location",
        filters: [geographicalRange(geoRange)],
      });
    }
    if (!!polyline && polyline.length > 0) {
      results.push({
        fieldName: "Location",
        filters: [geographicalArea(polyline)],
      });
    }
    if (floorArea.max || floorArea.min) {
      results.push(this.addMinMax(floorArea, "FloorArea") as Field);
    }
    if (numberOfParkingSpaces.max || numberOfParkingSpaces.min) {
      results.push(this.addMinMax(numberOfParkingSpaces, "NumberOfParkingSpaces") as Field);
    }
    if (siteArea.max || siteArea.min) {
      results.push(this.addMinMax(siteArea, "SiteArea") as Field);
    }
    if (numberOfStoreys.max || numberOfStoreys.min) {
      results.push(this.addMinMax(numberOfStoreys, "NumberOfStoreys") as Field);
    }
    if (numberOfUnits.max || numberOfUnits.min) {
      results.push(this.addMinMax(numberOfUnits, "NumberOfUnits") as Field);
    }
    if (valueMax && valueMin) {
      results.push({
        fieldName: "Value",
        filters: [between(valueMin, valueMax)],
      } as Field);
    } else {
      if (valueMax) {
        results.push({
          fieldName: "Value",
          filters: [lessThan(valueMax)],
        } as Field);
      }
      if (valueMin) {
        results.push({
          fieldName: "Value",
          filters: [greaterThan(valueMin)],
        } as Field);
      }
    }
    if (stages && stages.length) {
      results.push({
        fieldName: "Stage",
        filters: stages.map(stage => compare(stage)),
      } as Field);
    }
    if (roles && roles.length) {
      results.push({
        fieldName: "Companies.RoleName",
        filters: roles.map(role => compare(role)),
      } as Field);
    }
    if (fundingTypes && fundingTypes.length) {
      results.push({
        fieldName: "FundingType",
        filters: fundingTypes.map(fundingType => compare(fundingType)),
      } as Field);
    }
    if (constructionTypes && constructionTypes.length) {
      results.push({
        fieldName: "ConstructionType",
        filters: constructionTypes.map(constructionType => compare(constructionType)),
      } as Field);
    }
    if (planningAuthorities && planningAuthorities.length) {
      results.push({
        fieldName: "PlanningAuthority",
        filters: planningAuthorities.map(planningAuthority => compare(planningAuthority)),
      } as Field);
    }
    if (categories && categories.length) {
      results.push({
        fieldName: "Categories",
        filters: categories.map(category => compareWithId(category)),
      } as Field);
    }
    if (counties && counties.length) {
      results.push({
        fieldName: "County",
        filters: counties.map(county => compare(county)),
      } as Field);
    }
    if (dates) {
      dates.forEach(x => {
        if (x.startDate && x.endDate) {
          results.push({
            fieldName: x.filterName,
            filters: [between(x.startDate, x.endDate)],
          } as Field);
        } else if (x.startDate) {
          results.push({
            fieldName: x.filterName,
            filters: [greaterThan(x.startDate)],
          });
        } else if (x.endDate) {
          results.push({
            fieldName: x.filterName,
            filters: [lessThan(x.endDate)],
          });
        } else if (x.noOfdays || x.value) {
          results.push({
            fieldName: x.filterName,
            filters: [relativeDate(x.value || "", x.noOfdays || 0)],
          } as Field);
        }
      });
    }
    if (isSelfBuild && isSelfBuild.length) {
      results.push({
        fieldName: "IsSelfBuild",
        filters: [compare(isSelfBuild === "SelfBuildOnly" ? "true" : "false")],
      } as Field);
    }
    if (isFramework && isFramework.length && isFramework !== "Any") {
      results.push({
        fieldName: "IsFramework",
        filters: [compare(isFramework === "Yes" ? "true" : "false")],
      } as Field);
    }
    if (trackingFilter && trackingFilter.length) {
      results.push({
        fieldName: "trackingFilter",
        filters: [compare(trackingFilter)],
      } as Field);
    }
    return results;
  },
  addMinMax(range: IMaxMinValue, field: string) {
    if (range.max && range.min) {
      return ({
        fieldName: field,
        filters: [between(range.min, range.max)],
      } as Field);
    } else {
      if (range.max) {
        return ({
          fieldName: field,
          filters: [lessThan(range.max)],
        } as Field);
      }
      if (range.min) {
        return ({
          fieldName: field,
          filters: [greaterThan(range.min)],
        } as Field);
      }
    }
  },
};

const geographicalArea = (date: number[][]): Filter => {
  // const p = date.map(x => [x.lat, x.lng]) as number[][];
  return {
    filterType: "GeographicalArea",
    value: "",
    idValue: undefined,
    from: "",
    to: "",
    noOfDays: 0,
    operator: 0,
    points: date,
  } as Filter;
};

const geographicalRange = (data: IGeoRange): Filter => {
  return {
    filterType: "GeographicalRange",
    value: "",
    idValue: undefined,
    lat: data.center[0],
    lng: data.center[1],
    range: data.range,
    from: "",
    to: "",
    noOfDays: 0,
    operator: 0,
  } as Filter;
};
const relativeDate = (value: string, noOfDays: number): Filter => {
  return {
    filterType: "RelativeDate",
    value,
    idValue: undefined,
    from: "",
    to: "",
    noOfDays,
    operator: 0,
  } as Filter;
};
const between = (min: string, max: string): Filter => {
  return {
    filterType: "Between",
    value: "",
    idValue: undefined,
    from: min,
    to: max,
    noOfDays: 0,
    operator: 0,
  } as Filter;
};
const lessThan = (val: string): Filter => {
  return {
    filterType: "LessThan",
    value: val,
    idValue: undefined,
    from: "",
    to: "",
    noOfDays: 0,
    operator: 0,
  } as Filter;
};
const greaterThan = (val: string): Filter => {
  return {
    filterType: "GreaterThan",
    value: val,
    idValue: undefined,
    from: "",
    to: "",
    noOfDays: 0,
    operator: 0,
  } as Filter;
};
const compare = (val: string): Filter => {
  return {
    filterType: "Compare",
    value: val,
    idValue: undefined,
    from: "",
    to: "",
    noOfDays: 0,
    operator: 0,
  } as Filter;
};
const compareWithId = (val: string): Filter => {
  return {
    filterType: "Compare",
    value: "",
    idValue: val,
    from: "",
    to: "",
    noOfDays: 0,
    operator: 0,
  } as Filter;
};

const mapDateFilter = (filter: Filter, field: Field): DateRangeFilter => {
  switch (filter.filterType) {
    case "GreaterThan":
      return {
        startDate: filter.value,
        endDate: filter.to,
        value: "",
        filterName: field.fieldName,
      };
    case "LesserThan":
      return {
        startDate: "",
        endDate: filter.value,
        value: "",
        filterName: field.fieldName,
      };
      break;
    default:
      return {
        startDate: filter.from,
        endDate: filter.to,
        value: filter.value,
        filterName: field.fieldName,
      };
  }
};
