import { roleFlag } from "../constants";

export const IconService = {
  getResearchers() {
    return [
      {
        key: 1,
        name: "Scott Delaney",
        email: "sdelaney@cisireland.com",
        photo:
          "https://cisireland.blob.core.windows.net/public/scott-delaney.jpg",
      },
      {
        key: 2,
        name: "Sharon O'Rourke",
        email: "sorourke@cisireland.com",
        photo:
          "https://cisireland.blob.core.windows.net/public/sharon-orourke.jpg",
      },
      {
        key: 3,
        name: "Niall Gargan",
        email: "ngargan@cisireland.com",
        photo:
          "https://cisireland.blob.core.windows.net/public/niall-gargan.jpg",
      },
      {
        key: 4,
        name: "Cheryl McLorn",
        email: "cmclorn@cisireland.com",
        photo:
          "https://cisireland.blob.core.windows.net/public/cheryl-mclorn.jpg",
      },
      {
        key: 5,
        name: "Clare Lennon",
        email: "clennon@cisireland.com",
        photo:
          "https://cisireland.blob.core.windows.net/public/clare-lennon.jpg",
      },
      {
        key: 6,
        name: "Adam Dargan",
        email: "adargan@cisireland.com",
        photo:
          "https://cisireland.blob.core.windows.net/public/adam-dargan.jpg",
      },
    ];
  },
  getArray() {
    return [
      {
        id: 1,
        name: "Industrial",
        icon: "cis-icon-factory",
        researcher: 2,
        niResearcher: 4,
      },
      {
        id: 2,
        name: "Commercial",
        icon: "cis-icon-building",
        researcher: 6,
        niResearcher: 4,
      },
      {
        id: 3,
        name: "Residential",
        icon: "cis-icon-house",
        researcher: 6,
        niResearcher: 4,
      },
      {
        id: 4,
        name: "Education",
        icon: "cis-icon-mortarboard",
        researcher: 1,
        niResearcher: 4,
      },
      {
        id: 5,
        name: "Medical",
        icon: "cis-icon-medical",
        researcher: 1,
        niResearcher: 4,
      },
      {
        id: 6,
        name: "Civil and Utilities",
        icon: "cis-icon-bus",
        researcher: 2,
        niResearcher: 5,
      },
      {
        id: 7,
        name: "Community and Sport",
        icon: "cis-icon-basketball",
        researcher: 4,
        niResearcher: 5,
      },
      {
        id: 8,
        name: "Hospitality",
        icon: "cis-icon-food",
        researcher: 5,
        niResearcher: 5,
      },
      {
        id: 9,
        name: "Agriculture",
        icon: "cis-icon-grain",
        researcher: 2,
        niResearcher: 5,
      },
      {
        id: 10,
        name: "Design and Professional",
        icon: "cis-icon-paint",
        researcher: 1,
        niResearcher: 5,
      },
      {
        id: 11,
        name: "Construction Supplies",
        icon: "cis-icon-crane",
        researcher: 1,
        niResearcher: 5,
      },
    ];
  },
  getCategoryArray() {
    return [
      { id: 2, name: "Factory & Industrial", sectorId: 1 },
      { id: 3, name: "Warehouse and Storage", sectorId: 1 },
      { id: 4, name: "Laboratory & Research", sectorId: 1 },
      { id: 5, name: "Shops and Retail", sectorId: 2 },
      { id: 6, name: "Office", sectorId: 2 },
      { id: 7, name: "Petrol Stations", sectorId: 2 },
      { id: 8, name: "Car Showrooms", sectorId: 2 },
      { id: 9, name: "Housing Developments", sectorId: 3 },
      { id: 10, name: "Apartments", sectorId: 3 },
      { id: 11, name: "Single Dwellings", sectorId: 3 },
      { id: 12, name: "Residential Extensions", sectorId: 3 },
      { id: 13, name: "Schools", sectorId: 4 },
      { id: 14, name: "Universities & Colleges", sectorId: 4 },
      { id: 15, name: "Crèches & Nurseries", sectorId: 4 },
      { id: 16, name: "Hospitals", sectorId: 5 },
      { id: 17, name: "Nursing Homes", sectorId: 5 },
      { id: 18, name: "Other Medical Facilities", sectorId: 5 },
      { id: 19, name: "Transport (Roads & Bridges)", sectorId: 6 },
      { id: 20, name: "Transport (Air, Sea, Rail)", sectorId: 6 },
      { id: 21, name: "Car Parks (Standalone)", sectorId: 6 },
      { id: 22, name: "Water & Sewerage", sectorId: 6 },
      { id: 23, name: "Power (Electricity, Windfarms etc.)", sectorId: 6 },
      { id: 24, name: "Communications", sectorId: 6 },
      { id: 25, name: "Landscaping and Fencing", sectorId: 6 },
      { id: 26, name: "Sports Clubs & Grounds", sectorId: 7 },
      { id: 27, name: "Leisure Facilities", sectorId: 7 },
      { id: 28, name: "Churches", sectorId: 7, icon: "cis-icon-church" },
      { id: 29, name: "Public Buildings", sectorId: 7 },
      { id: 30, name: "Community Centres", sectorId: 7 },
      { id: 31, name: "Playgrounds", sectorId: 7 },
      { id: 32, name: "Hotels", sectorId: 8 },
      { id: 33, name: "Restaurants, Bars, Nightclubs", sectorId: 8 },
      { id: 34, name: "Guest Houses and Hostels", sectorId: 8 },
      { id: 35, name: "Agricultural Buildings", sectorId: 9 },
      { id: 36, name: "Advertising", sectorId: 2 },
      { id: 37, name: "Design and Professional", sectorId: 10 },
      { id: 38, name: "Construction Supplies", sectorId: 11 },
      { id: 40, name: "Data Centres", sectorId: 1 },
    ];
  },
  getIconByName(sectorName: string) {
    const sectorIcons = this.getArray();
    const selected = sectorIcons.find(i => i.name === sectorName);
    if (selected) {
      return selected.icon;
    } else {
      return "cis-icon-building";
    }
  },
  getIconByRoleName(roleName: string) {
    const selected = (roleFlag as any)[roleName];
    if (selected) {
      return selected;
    } else {
      return "cis-icon-building";
    }
  },
  getIconById(sectorId: number) {
    const sectorIcons = this.getArray();
    const selected = sectorIcons.find(i => i.id === sectorId);
    if (selected) {
      return selected.icon;
    } else {
      return "cis-icon-building";
    }
  },
  getIconByCategoryName(categoryName: string) {
    const categories = this.getCategoryArray();
    const selectedCategory = categories.find(i => i.name === categoryName);
    if (selectedCategory) {
      if (selectedCategory.icon) {
        return selectedCategory.icon;
      }
      return this.getIconById(selectedCategory.sectorId);
    }
    return "cis-icon-building";
  },
  getResearcherBySectorName(sectorName: string, county: string) {
    const sector = this.getArray().find(i => i.name === sectorName);
    const niCounties = [
      "Co. Antrim",
      "Co. Armagh",
      "Co. Down",
      "Co. Fermanagh",
      "Co. Londonderry",
      "Co. Tyrone",
    ];

    if (niCounties.find(c => c === county)) {
      if (sector) {
        return this.getResearchers().find(r => r.key === sector.niResearcher);
      }
    }

    if (sector) {
      return this.getResearchers().find(r => r.key === sector.researcher);
    } else {
      return this.getResearchers()[0];
    }
  },
};
